/*eslint-disable*/ 
import React from "react";
import Layout from 'components/layout'
import Seo from 'components/seo'
import { makeStyles } from "@material-ui/core/styles";
import { graphql } from "gatsby"

// kit
// core components
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Primary from "components/Typography/Primary.js";

import shingenStyle from "style/kit_shingenStyle.js";

// img
import logo_l from "images/logo_l.svg"
import sorry from "images/sorry.png"

import SectionBukkenList from "views/Shingen/SectionBukkenList.js";

const useStyles = makeStyles(shingenStyle);

const BlogIndex = ({ data }) => {
  const classes = useStyles();
  const siteTitle = "pickup賃貸：戸建賃貸"
  const pageTitle = "pickup賃貸情報：戸建て賃貸"
  const siteDescription = "申源のオススメする、戸建ての賃貸物件を集めました！"
  const posts = data.allMarkdownRemark.nodes
  return (
    <Layout active="2">
      <Seo title={siteTitle} description={siteDescription}/>
      <div>
        <Parallax image={require("images/header_back6.jpg").default} filter="dark" 
          className={classes.parallax}
        >
          <div className={classes.container}>
            <GridContainer justifyContent="center">
              <GridItem xs={12} className={classes.textCenter} >
                <h1 className={classes.title_2} id="logo_l">
                  <img src={logo_l} alert="有限会社 申源"/>
                </h1>
                <h2 className={classes.h2_subtitle}>{ pageTitle }</h2>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classes.main}>
            <div className={classes.container}>
              <Primary className={classes.description}>
                {siteDescription}
              </Primary>
              <hr className={classes.hr} />
              <div>
                当社に直接お問い合わせいただいた方は仲介手数料半額！<br />
                管理会社だから、他社に紹介してもらうより、直接問合せ後契約をするのが一番安いです。<br />
                無駄なサービス（消火器購入や入居前消毒）を勧めません。<br />
              </div>
              <hr className={classes.hr} />
              <SectionBukkenList pageTitle={pageTitle} siteDescription={siteDescription} posts={posts}/>
            </div>
        </div>
      </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(filter: {frontmatter: {tags: { in:"戸建賃貸" }}}){
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          tags
          hero{
            childImageSharp {
              fluid(maxWidth: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          listimage{
            childImageSharp {
              fluid(maxWidth: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

